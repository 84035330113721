import account_en from './locales/en/account.json';
import affiliate_en from './locales/en/affiliate.json';
import announcements_en from './locales/en/announcements.json';
import auth_en from './locales/en/auth.json';
import bets_en from './locales/en/bets.json';
import comments_en from './locales/en/comments.json';
import common_en from './locales/en/common.json';
import dates_en from './locales/en/dates.json';
import dropzone_en from './locales/en/dropzone.json';
import editor_en from './locales/en/editor.json';
import events_en from './locales/en/events.json';
import fights_en from './locales/en/fights.json';
import footer_en from './locales/en/footer.json';
import header_en from './locales/en/header.json';
import help_en from './locales/en/help.json';
import live_orders_en from './locales/en/live-orders.json';
import news_en from './locales/en/news.json';
import notifications_en from './locales/en/notifications.json';
import period_en from './locales/en/period.json';
import seo_en from './locales/en/seo.json';
import settings_en from './locales/en/settings.json';
import tournaments_en from './locales/en/tournaments.json';
import verifications_en from './locales/en/verifications.json';
import vote_en from './locales/en/vote.json';
import wallets_en from './locales/en/wallets.json';
import account_ru from './locales/ru/account.json';
import affiliate_ru from './locales/ru/affiliate.json';
import announcements_ru from './locales/ru/announcements.json';
import auth_ru from './locales/ru/auth.json';
import bets_ru from './locales/ru/bets.json';
import comments_ru from './locales/ru/comments.json';
import common_ru from './locales/ru/common.json';
import dates_ru from './locales/ru/dates.json';
import dropzone_ru from './locales/ru/dropzone.json';
import editor_ru from './locales/ru/editor.json';
import events_ru from './locales/ru/events.json';
import fights_ru from './locales/ru/fights.json';
import footer_ru from './locales/ru/footer.json';
import header_ru from './locales/ru/header.json';
import help_ru from './locales/ru/help.json';
import live_orders_ru from './locales/ru/live-orders.json';
import news_ru from './locales/ru/news.json';
import notifications_ru from './locales/ru/notifications.json';
import period_ru from './locales/ru/period.json';
import seo_ru from './locales/ru/seo.json';
import settings_ru from './locales/ru/settings.json';
import tournaments_ru from './locales/ru/tournaments.json';
import verifications_ru from './locales/ru/verifications.json';
import vote_ru from './locales/ru/vote.json';
import wallets_ru from './locales/ru/wallets.json';

export const resources = {
  en: {
    account: account_en,
    affiliate: affiliate_en,
    announcements: announcements_en,
    auth: auth_en,
    bets: bets_en,
    comments: comments_en,
    common: common_en,
    dates: dates_en,
    dropzone: dropzone_en,
    editor: editor_en,
    events: events_en,
    footer: footer_en,
    header: header_en,
    live_orders: live_orders_en,
    help: help_en,
    news: news_en,
    notifications: notifications_en,
    period: period_en,
    settings: settings_en,
    seo: seo_en,
    tournaments: tournaments_en,
    verifications: verifications_en,
    vote: vote_en,
    fights: fights_en,
    wallets: wallets_en,
  },
  ru: {
    account: account_ru,
    affiliate: affiliate_ru,
    announcements: announcements_ru,
    auth: auth_ru,
    bets: bets_ru,
    comments: comments_ru,
    common: common_ru,
    dates: dates_ru,
    dropzone: dropzone_ru,
    editor: editor_ru,
    events: events_ru,
    footer: footer_ru,
    header: header_ru,
    live_orders: live_orders_ru,
    help: help_ru,
    news: news_ru,
    notifications: notifications_ru,
    period: period_ru,
    settings: settings_ru,
    seo: seo_ru,
    tournaments: tournaments_ru,
    verifications: verifications_ru,
    vote: vote_ru,
    fights: fights_ru,
    wallets: wallets_ru,
  },
};
