import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface LogoutClientMutation {
  logoutClient:
    | {
        __typename: 'LogoutClient';
        client: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface LogoutClientMutationVars {}

const LOGOUT_CLIENT = gql`
  mutation LogoutClient {
    logoutClient {
      __typename
      ... on LogoutClient {
        client {
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default LOGOUT_CLIENT;
