import { useMutation } from '@apollo/client';
import React, { createRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import RESTORE_CLIENT_PASSWORD, {
  RestoreClientPasswordMutation,
  RestoreClientPasswordMutationVars,
} from 'features/forgot-password/graphql/restore-client-password';
import { Toast } from 'ui';

import ForgotPasswordModal, {
  ForgotPasswordModalFormErrors,
  ForgotPasswordModalFormValues,
} from './forgot-password-modal';

const ForgotPasswordModalConnected: React.FC<{
  isOpen: boolean;
  onGoToSignIn: () => void;
  onClose?: () => void;
  noOverflow?: boolean;
}> = ({ isOpen, onClose, onGoToSignIn, noOverflow }) => {
  const recaptcha = createRef<ReCAPTCHA>();

  const { t } = useTranslation(['common']);

  const [processing, setProcessing] = useState(false);
  const [isEmailSend, setEmailSend] = useState(false);

  const [formValues, setFormValues] = useState<ForgotPasswordModalFormValues>({
    email: '',
  });
  const [formErrors, setFormErrors] = useState<ForgotPasswordModalFormErrors>(
    {}
  );

  useEffect(() => {
    if (isOpen && isEmailSend) {
      setEmailSend(false);
    }
  }, [isOpen]);

  const [restoreClientPassword] = useMutation<
    RestoreClientPasswordMutation,
    RestoreClientPasswordMutationVars
  >(RESTORE_CLIENT_PASSWORD);

  return (
    <ForgotPasswordModal
      values={formValues}
      errors={formErrors}
      recaptchaRef={recaptcha}
      isOpen={isOpen}
      isEmailSend={isEmailSend}
      processing={processing}
      onChange={(values) => {
        setFormValues({
          ...formValues,
          ...values,
        });
        setFormErrors({
          ...formErrors,
          ...Object.keys(values).reduce(
            (prev, cur) => ({ ...prev, [cur]: undefined }),
            {}
          ),
        });
      }}
      onClose={onClose}
      onGoToSignIn={onGoToSignIn}
      onSubmit={async () => {
        try {
          setProcessing(true);

          const { data, errors } = await restoreClientPassword({
            variables: {
              ...formValues,
              recaptcha: recaptcha?.current?.getValue() || null,
            },
          });

          if (errors) {
            Toast.error(errors.map((item) => item.message).join(', '));
            return;
          }

          if (!data) {
            Toast.error(t('common:errors:undefined_error'));
            return;
          }

          if (data?.restoreClientPassword.__typename === 'Error') {
            if (data.restoreClientPassword.warnings) {
              return setFormErrors(
                data.restoreClientPassword.warnings.reduce(
                  (prev, cur) => ({
                    ...prev,
                    [cur.key]: cur.warning,
                  }),
                  {}
                )
              );
            }
            Toast.error(data.restoreClientPassword.error);
            return;
          }
          setEmailSend(true);
        } catch (e: unknown) {
        } finally {
          setProcessing(false);
        }
      }}
      noOverflow={noOverflow}
    />
  );
};

export default ForgotPasswordModalConnected;
