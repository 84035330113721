import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import config from 'application/config';
import { getLanguage } from 'application/utils/get-language';

import css from './recaptcha.module.scss';

interface ReCaptchaProps {
  error?: string;
}

const ReCaptcha = forwardRef<ReCAPTCHA, ReCaptchaProps>(function ReCaptcha(
  { error },
  ref
) {
  return (
    <div>
      <ReCAPTCHA hl={getLanguage()} ref={ref} sitekey={config.recaptchKey} />
      {error && <div className={css.Error}>{error}</div>}
    </div>
  );
});

export default ReCaptcha;
