import React, { useState } from 'react';

import { EyeIcon } from 'ui/icons';

import { InputProps } from './input';
import css from './password-input.module.scss';

import Input from '.';

const PasswordInput: React.FC<Omit<InputProps, 'type' | 'right'>> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      type={showPassword ? 'input' : 'password'}
      right={
        <div
          className={css.Button}
          onClick={() => setShowPassword(!showPassword)}
        >
          <EyeIcon />
        </div>
      }
      {...props}
    />
  );
};

export default PasswordInput;
