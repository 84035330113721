import cx from 'classnames';
import React, { ReactNode } from 'react';

import css from './tabs.module.scss';

type TabSize = 'small' | 'medium' | 'large';

interface TabsProps<T> {
  children?: ReactNode;
  small?: boolean;
  type?: TabSize;
  value: T;
  items: { value: T; label: string; count?: number; disabled?: boolean }[];
  onSelect: (value: T) => void;
}

type TabsFC = <T>(props: TabsProps<T>) => React.ReactElement<TabsProps<T>>;

const Tabs: TabsFC = ({
  value,
  items,
  onSelect,
  type = 'large',
  small = false,
  children,
}) => {
  const sizeMap: Map<TabSize, any> = new Map()
    .set('small', css.RootSmall)
    .set('medium', css.RootMedium)
    .set('large', css.Root);

  if (small) type = 'small';

  return (
    <div className={sizeMap.get(type)}>
      <ul className={css.List}>
        {items.map((item, index) => (
          <li
            key={index}
            className={cx(css.Item, item.disabled && css.ItemDisabled)}
            onClick={() => !item.disabled && onSelect(item.value)}
          >
            <div className={value === item.value ? css.ItemActive : undefined}>
              {item.label}
            </div>
            {item.count && <div className={css.Count}> {item.count}</div>}
          </li>
        ))}
      </ul>
      {children}
    </div>
  );
};

export default Tabs;
