import { initializeApp } from 'firebase/app';

import config from '../config';

const getFirebaseApp = () => {
  if (!config.firebase) {
    return null;
  }

  return initializeApp(config.firebase);
};

export default getFirebaseApp;
