import Header from '@editorjs/header';
import List from '@editorjs/list';
import Quote from '@editorjs/quote';
import { TFunction } from 'i18next';

import type { LoadNewsWidgetLinkType } from 'features/news/types/load-news-widget-link-type';
import type { UploadType } from 'features/news/types/upload-type';

import Widget from './widget';

export const editorI18n = {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': 'Нажмите, чтобы настроить',
          'or drag to move': 'или перетащите',
        },
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Преобразовать в',
        },
      },
      toolbar: {
        toolbox: {
          Add: 'Добавить',
        },
      },
      popover: {
        Filter: 'Поиск',
        'Nothing found': 'Ничего не найдено',
      },
    },
    toolNames: {
      Text: 'Текст',
      Heading: 'Заголовок',
      Quote: 'Цитата',
      Image: 'Фото',
      Video: 'Видео',
      List: 'Список',
      Bold: 'Полужирный',
      Italic: 'Курсив',
      Widget: 'Виджет',
    },
    tools: {
      warning: {
        Title: 'Название',
        Message: 'Сообщение',
      },
      image: {
        'Select an Image': 'Загрузите изображение',
        'With border': 'С рамкой',
        'Stretch image': 'Растянуть',
        'With background': 'Сделать фоном',
      },
      header: {
        'Heading 2': 'Заголовок 2',
        'Heading 3': 'Заголовок 3',
        'Heading 4': 'Заголовок 4',
      },
      quote: {
        'Align Left': 'Слева',
        'Align Center': 'По центру',
      },
      list: {
        Unordered: 'Маркированный',
        Ordered: 'Нумерованный',
      },
      stub: {
        'The block can not be displayed correctly.':
          'Блок не может быть отображен',
      },
    },
    blockTunes: {
      delete: {
        Delete: 'Удалить',
        'Click to delete': 'Точно удалить?',
      },
      moveUp: {
        'Move up': 'Переместить вверх',
      },
      moveDown: {
        'Move down': 'Переместить вниз',
      },
    },
  },
};

export const editorTools = (
  t: TFunction,
  tools: Record<'image' | 'video', any>,
  callbacks: Record<'image' | 'video', UploadType | undefined>,
  loadWidgetLink?: LoadNewsWidgetLinkType
) => {
  Object.defineProperty(tools.image, 'tunes', {
    get: function () {
      return [
        {
          name: 'stretched',
          icon: stretchImageIcon,
          title: 'Stretch image',
          toggle: !0,
        },
      ];
    },
  });

  return {
    video: {
      class: tools.video,
      config: {
        types: 'video/mp4',
        buttonContent: t('editor:video'),
        player: {
          controls: false,
          autoplay: true,
          loop: true,
        },
        captionPlaceholder: ' ',
        uploader: {
          uploadByFile: callbacks.video,
        },
      },
    },
    image: {
      class: tools.image,
      config: {
        captionPlaceholder: ' ',
        uploader: {
          uploadByFile: callbacks.image,
        },
      },
    },
    header: {
      class: Header,
      config: {
        levels: [2, 3, 4],
        defaultLevel: 2,
      },
    },
    list: {
      class: List,
      inlineToolbar: true,
      config: {
        defaultStyle: 'unordered',
      },
    },
    quote: {
      class: Quote,
      inlineToolbar: true,
      config: {
        quotePlaceholder: t('editor:quote_placeholder'),
        captionPlaceholder: t('editor:quote_caption_placeholder'),
      },
    },

    widget: {
      class: Widget as any,
      config: {
        loadWidgetLink: loadWidgetLink,
        placeholder: t('editor:widget_placeholder'),
      },
    },
  };
};

export const stretchImageIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9L20 12L17 15"/><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 12H20"/><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 9L4 12L7 15"/><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 12H10"/></svg>';
export const widgetIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="2 2 20 20"><path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M7.69998 12.6L7.67896 12.62C6.53993 13.7048 6.52012 15.5155 7.63516 16.625V16.625C8.72293 17.7073 10.4799 17.7102 11.5712 16.6314L13.0263 15.193C14.0703 14.1609 14.2141 12.525 13.3662 11.3266L13.22 11.12"></path><path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M16.22 11.12L16.3564 10.9805C17.2895 10.0265 17.3478 8.5207 16.4914 7.49733V7.49733C15.5691 6.39509 13.9269 6.25143 12.8271 7.17675L11.3901 8.38588C10.0935 9.47674 9.95706 11.4241 11.0888 12.6852L11.12 12.72"></path></svg>';

export const MAX_BLOCK_COUNT = 100;
