import EventAbstract from './event-abstract';

export type SignupEventData = {
  type: 'email' | 'google';
};

class SignupEvent extends EventAbstract<SignupEventData> {
  eventName = 'signup';
}

export default SignupEvent;
