import { JSXElement } from '@babel/types';
import React, { ReactElement, ReactNode } from 'react';

import { useCountdown } from '../../hooks/use-countdown';

interface CountdownWrapperProps {
  children: ({
    days,
    hours,
    minutes,
    seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }) => ReactElement;
  targetDate: Date;
}

export const CountdownWrapper = ({
  children,
  targetDate,
}: CountdownWrapperProps) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return children({ days, hours, minutes, seconds });
};
