import { LanguageType } from '../types/language-type';

export const getLanguage = (cookie?: string): LanguageType => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('i18nextLng') as LanguageType;
  } else if (cookie) {
    const match = cookie.match(/i18next=([^;]*)/);
    return match ? (match[1] as LanguageType) : 'ru';
  }
  return 'ru';
};
