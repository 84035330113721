import React from 'react';

import { Button } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './pagination.module.scss';

const MAX_VISIBLE_PAGE = 6;
const DOTS = '...';

interface PaginationProps {
  page: number;
  totalItems: number;
  perPageLimit: number;
  setPage: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  totalItems,
  perPageLimit,
  setPage,
}) => {
  const totalPages = Math.ceil(totalItems / perPageLimit);
  const pageNumbers: number[] = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const getVisiblePages = (current: number, total: number) => {
    if (total <= MAX_VISIBLE_PAGE) {
      return pageNumbers;
    }

    if (current <= 4) {
      return [...pageNumbers.slice(0, 5), DOTS, total];
    } else if (current >= total - 3) {
      return [1, DOTS, ...pageNumbers.slice(total - 5)];
    } else {
      return [
        1,
        DOTS,
        ...pageNumbers.slice(current - 2, current + 1),
        DOTS,
        total,
      ];
    }
  };

  if (!page || !totalItems) return null;

  return (
    <ul>
      <Flex gap={GridGap.x1} alignItems="center">
        <Button
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
          color="invert"
          className={css.Button}
        >
          &lt;
        </Button>

        {getVisiblePages(page, totalPages).map((number, index) => (
          <li key={index}>
            {number === DOTS ? (
              <span className={css.Dots}>{number}</span>
            ) : (
              <Button
                onClick={() => typeof number === 'number' && setPage(number)}
                color={page === number ? 'primary' : 'empty'}
                className={css.Button}
              >
                {number}
              </Button>
            )}
          </li>
        ))}

        <Button
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
          color="invert"
          className={css.Button}
        >
          &gt;
        </Button>
      </Flex>
    </ul>
  );
};

export default Pagination;
