import { type FirebaseApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const retrievePushToken = async (firebaseApp: FirebaseApp) => {
  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    const messaging = getMessaging(firebaseApp);

    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      const currentToken = await getToken(messaging);
      if (currentToken) {
        return currentToken;
      } else {
        throw new Error(
          'No registration token available. Request permission to generate one.'
        );
      }
    }
  }

  return null;
};

export default retrievePushToken;
