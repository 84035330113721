abstract class EventAbstract<T extends unknown | undefined = undefined> {
  abstract readonly eventName: string;
  private data: T;

  constructor(data: T) {
    this.data = data;
  }

  getEventName() {
    return this.eventName;
  }
  getEventProperties() {
    return this.data;
  }
}

export default EventAbstract;
