import React, { useCallback, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import useBodyOverflow from 'application/hooks/use-body-overflow';
import MobileSwiper from 'ui/mobile-swiper';

import css from './bottom-panel.module.scss';

interface BototmPanelProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  id?: string;
}

const BototmPanel: React.FC<BototmPanelProps> = ({
  open,
  onClose,
  children,
  id,
}) => {
  const { setOverflowed } = useBodyOverflow();

  const onSwipe = useCallback(
    ({ deltaX, deltaY }: { deltaX: number; deltaY: number }) => {
      if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY > 0) {
        onClose?.();
      }
    },
    []
  );

  useEffect(() => {
    setOverflowed(open);
  }, [open, setOverflowed]);

  return (
    <CSSTransition
      in={open}
      timeout={300}
      classNames={{
        enter: css.RootEnter,
        enterActive: css.RootEnterActive,
        enterDone: css.RootOpened,
        exit: css.RootExit,
        exitActive: css.RootExitActive,
        exitDone: css.Root,
      }}
    >
      <div className={css.Root} id={id}>
        <div className={css.Close} onClick={onClose} />
        <div className={css.Panel}>
          <MobileSwiper onSwipe={onSwipe}>
            <div className={css.PanelClose} onClick={onClose} />
          </MobileSwiper>
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

export default BototmPanel;
