import { useEffect, useRef, useState } from 'react';

const useBodyOverflow = () => {
  const scrollPosition = useRef(0);
  const [overflowed, setOverflowed] = useState(false);

  useEffect(() => {
    if (overflowed) {
      scrollPosition.current = window.pageYOffset;
      document.body.style.width = '100vw';
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition.current}px`;
    } else {
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('top');
      document.body.style.removeProperty('width');
      window.scrollTo(0, scrollPosition.current);
    }
  }, [overflowed]);

  return { overflowed, setOverflowed };
};

export default useBodyOverflow;
