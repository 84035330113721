import { type FirebaseApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import getPushToken from 'application/firebase/get-push-token';
import retrievePushToken from 'application/firebase/retrieve-push-token';
import { Toast } from 'ui';

import getFirebaseApp from '../../../application/firebase/firebase';

const FirebaseContext = createContext<{
  firebaseApp: FirebaseApp | null;
  token: string | null | undefined;
  retrievePushToken: () => Promise<void>;
}>({
  firebaseApp: null,
  token: null,
  retrievePushToken: async () => {},
});

export const useFirebaseApp = () => useContext(FirebaseContext);

const FirebaseProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const firebaseApp = useRef(getFirebaseApp());

  const [token, setToken] = useState<string | null | undefined>(undefined);

  useEffect(() => {
    if (firebaseApp.current) {
      getPushToken(firebaseApp.current)
        .then((newToken) => setToken(newToken))
        .catch(console.error);
    }

    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      firebaseApp.current
    ) {
      const messaging = getMessaging(firebaseApp.current);
      const unsubscribe = onMessage(messaging, (payload) => {
        if (payload.notification?.title) {
          Toast.info(payload.notification.title, payload.notification.body);
        }

        console.log('Foreground push notification received:', payload);
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        firebaseApp: firebaseApp.current,
        token,
        retrievePushToken: async () => {
          if (firebaseApp.current) {
            try {
              const token = await retrievePushToken(firebaseApp.current);
              console.log(token);

              setToken(token);
            } catch (error) {
              console.error(error);
            }
          }
        },
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
