import cx from 'classnames';
import React, { CSSProperties } from 'react';

import { GridGap } from 'ui/grid/grid.types';

import css from './grid.module.scss';

export interface GridProps {
  children: React.ReactNode;
  gap?: GridGap;
  alignItems?: string;
  justifyItems?: string;
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  gridAutoRows?: string;
  width?: string;
  height?: string;
  className?: string;
  maxWidth?: string;
  marginBottom?: GridGap;
  marginTop?: GridGap;
  style?: CSSProperties;
}

export const Grid: React.FC<GridProps> = ({
  children,
  gap = GridGap.x1,
  alignItems,
  justifyItems,
  gridTemplateColumns = '1fr',
  gridTemplateRows = 'none',
  width = '100%',
  height = 'auto',
  className = '',
  gridAutoRows,
  marginBottom,
  marginTop,
  style,
  maxWidth,
  ...args
}) => {
  const styles = {
    ...style,
    ...(gap ? { gap } : null),
    ...(alignItems ? { alignItems } : null),
    ...(justifyItems ? { justifyItems } : null),
    ...(gridTemplateColumns ? { gridTemplateColumns } : null),
    ...(gridTemplateRows ? { gridTemplateRows } : null),
    ...(gridAutoRows ? { gridAutoRows } : null),
    ...(width ? { width } : null),
    ...(height ? { height } : null),
    ...(marginBottom ? { marginBottom } : null),
    ...(marginTop ? { marginTop } : null),
    ...(maxWidth ? { maxWidth } : null),
  };

  return (
    <div {...args} style={styles} className={cx(css.grid, className)}>
      {children}
    </div>
  );
};
