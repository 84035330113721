import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools/block-tool';
import DOMPurify from 'dompurify';

import { widgetIcon } from './editor-constants';
import { WidgetServices, WidgetServicesScriptSrc } from './editor-types';
import css from './widget.module.scss';

declare global {
  interface Window {
    twttr: any;
    instgrm: any;
  }
}

type WidgetData = {
  html?: string;
  url?: string;
  serviceName?: string;
};

type Service = {
  regex: RegExp;
  scriptSrc?: string;
};

class Widget {
  data: WidgetData;
  widgetData: WidgetData;
  loadWidgetLink: (url: string) => Promise<{
    success: number;
    html?: string;
    url?: string;
    serviceName?: string;
  }>;
  placeholder: string;
  element: HTMLDivElement | null;
  input: HTMLInputElement | null;

  constructor({ data, config }: BlockToolConstructorOptions<WidgetData>) {
    this.data = data; // previously saved data
    this.widgetData = {}; // current data
    this.loadWidgetLink = config.loadWidgetLink;
    this.placeholder = config.placeholder;
    this.input = null;
    this.element = null;
  }

  static title = 'Widget';

  static get toolbox() {
    return {
      title: this.title,
      icon: widgetIcon,
    };
  }

  render() {
    this.element = document.createElement('div');

    if (this.data?.url) {
      const { html, url, serviceName } = this.data;
      this.widgetData = { html, url, serviceName };

      if (html && serviceName) {
        this.createWidget(html, serviceName);
        return this.element;
      }
    }

    this.input = document.createElement('input');
    this.input.type = 'text';
    this.input.placeholder = this.placeholder;
    this.input.classList.add(css.Input);
    this.input.value = this.data.url || '';
    this.element.appendChild(this.input);

    this.input.addEventListener('input', (event: Event) => {
      const url = (event.target as HTMLInputElement).value;
      this.widgetData.url = url;

      if (this.findServiceNameByUrl(url)) {
        this.input?.classList.add(css.InputLoading);
        this.getWidgetData(url);
      }
    });

    return this.element;
  }

  async getWidgetData(widgetUrl: string) {
    const responseResult = await this.loadWidgetLink(widgetUrl);
    const { html, url, serviceName } = responseResult;

    if (html && serviceName) {
      this.widgetData = { html, url, serviceName };
      this.createWidget(html, serviceName);
    } else {
      this.input?.classList.replace(css.InputLoading, css.InputError);
    }
  }

  createWidget(html: string, serviceName: string) {
    const widgetContainer = document.createElement('div');
    widgetContainer.classList.add(css.Widget);

    if (serviceName === WidgetServices.FACEBOOK) {
      widgetContainer.innerHTML = html;
    }

    if (serviceName === WidgetServices.INSTAGRAM) {
      this.addPost(widgetContainer, html, serviceName);
      this.observeWidgetChange('.instagram-media', () =>
        window.instgrm?.Embeds?.process()
      );
    }

    if (serviceName === WidgetServices.TWITTER) {
      this.addPost(widgetContainer, html, serviceName);
      this.observeWidgetChange('.twitter-tweet', () =>
        window.twttr?.widgets?.load()
      );
    }

    if (
      serviceName === WidgetServices.YOUTUBE ||
      serviceName === WidgetServices.VK_VIDEO
    ) {
      const videoContainer = document.createElement('div');
      videoContainer.className = css.VideoWrapper;
      videoContainer.innerHTML = html;
      widgetContainer.appendChild(videoContainer);
    }

    this.input?.remove();
    this.element?.appendChild(widgetContainer);
  }

  findServiceNameByUrl(url: string) {
    for (const serviceName in Widget.services) {
      if (Widget.services[serviceName].regex.test(url)) {
        return serviceName;
      }
    }
    return null;
  }

  addPost(container: Element, html: string, serviceName: string) {
    container.innerHTML = DOMPurify.sanitize(html);
    const scriptSrc = Widget.services[serviceName].scriptSrc;
    if (!scriptSrc) {
      this.input?.classList.remove(css.InputLoading);
      return;
    }

    const existingScripts = Array.from(
      document.head.getElementsByTagName('script')
    );
    const scriptExists = existingScripts.some(
      (script) => script.src === scriptSrc
    );

    if (!scriptExists) {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      document.head.appendChild(script);
    }
  }

  observeWidgetChange(tag: string, callback: () => void) {
    if (!this.element) return;

    const observer = new MutationObserver((mutations, obs) => {
      if (this.element && this.element.querySelector(tag)) {
        callback();
        obs.disconnect();
      }
    });

    observer.observe(this.element, { childList: true, subtree: true });
  }

  save() {
    return this.widgetData;
  }

  static get services(): { [key: string]: Service } {
    return {
      [WidgetServices.YOUTUBE]: {
        regex:
          /(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/(?:v\/|u\/\w\/|embed\/|watch))(?:(\?v=)?([^#&?=]*))?((?:[?&]\w*=\w*)*)/,
      },
      [WidgetServices.VK_VIDEO]: {
        regex: /https?:\/\/vk\.com\/video(-?\d+_\d+)/,
      },
      [WidgetServices.FACEBOOK]: {
        regex: /(?:https?:\/\/)?(?:www\.)?(?:facebook\.com|fb\.me)/,
      },
      [WidgetServices.TWITTER]: {
        regex:
          /^https?:\/\/(?:twitter\.com|x\.com)\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)$/,
        scriptSrc: WidgetServicesScriptSrc.TWITTER,
      },
      [WidgetServices.INSTAGRAM]: {
        regex: /https?:\/\/(?:www\.)?instagram\.com/,
        scriptSrc: WidgetServicesScriptSrc.INSTAGRAM,
      },
    };
  }
}

export default Widget;
