import EventAbstract from './events/event-abstract';
import SigninEvent, { SigninEventData } from './events/signin-event';
import SignupEvent, { SignupEventData } from './events/signup-event';

declare global {
  interface Window {
    gtag?(type: string, name: string, data?: unknown): void;
  }
}

class AppAnalytic {
  public sendSignupEvent(data: SignupEventData) {
    this.sendEvent(new SignupEvent(data));
  }

  public sendSigninEvent(data: SigninEventData) {
    this.sendEvent(new SigninEvent(data));
  }

  private sendEvent<T>(event: EventAbstract<T>) {
    if (window.gtag) {
      window.gtag('event', event.getEventName(), event.getEventProperties());
    } else {
      console.log('event', event.getEventName(), event.getEventProperties());
    }
  }
}

export default AppAnalytic;
