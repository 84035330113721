import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface RestoreClientPasswordMutation {
  restoreClientPassword:
    | {
        __typename: 'RestoreClientPassword';
        client: {
          email: string;
        };
      }
    | GraphqlErrorType;
}

export interface RestoreClientPasswordMutationVars {
  email: string;
  recaptcha: string | null;
}

const RESTORE_CLIENT_PASSWORD = gql`
  mutation RestoreClientPassword($email: String!, $recaptcha: String) {
    restoreClientPassword(email: $email, recaptcha: $recaptcha) {
      __typename
      ... on RestoreClientPassword {
        client {
          email
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default RESTORE_CLIENT_PASSWORD;
