import { gql } from '@apollo/client';

export interface SportType {
  id: string;
  name: string;
  alias: string;
  icon: string;
}

export type AppConfig = {
  sports: SportType[];
  walletEnabled: boolean;
  referralEnabled: boolean;
};

export interface GetAppConfigQuery {
  getAppConfig: AppConfig;
}

export interface GetAppConfigQueryVars {}

const GET_APP_CONFIG = gql`
  query GetAppConfig {
    getAppConfig {
      sports {
        id
        name
        alias
        icon
      }
      walletEnabled
      referralEnabled
    }
  }
`;

export default GET_APP_CONFIG;
