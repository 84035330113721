import React from 'react';

import { useUser } from 'application/context/user-provider';

import PushTokenModal from './push-token-permission-modal';
import useFirebaseModalState from '../../hooks/use-fiebase-modal-state';

const PushTokenPermissionModalContainer: React.FC = () => {
  const { user, sendPushToken } = useUser();
  const [open, setOpen] = useFirebaseModalState();

  return (
    <PushTokenModal
      open={user !== null && open}
      sendPushToken={() => {
        setOpen(false);
        sendPushToken();
      }}
      close={() => setOpen(false)}
    />
  );
};

export default PushTokenPermissionModalContainer;
