import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';

type FlagType = keyof typeof Flags;

const getCountryFlag = (country: string | null | undefined) => {
  if (!country) {
    return null;
  }

  if (country === 'EN') {
    return Flags['GB'];
  }

  if (!hasFlag(country)) {
    return null;
  }

  return Flags[country as FlagType];
};

export default getCountryFlag;
