import cx from 'classnames';
import React, { Ref, forwardRef } from 'react';
import InputMask from 'react-input-mask';

import css from './input.module.scss';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  ref?: Ref<any>;
  type?: 'input' | 'email' | 'password' | 'tel';
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  right?: React.ReactNode;
  error?: string;
  mask?: string;
  disabled?: boolean;
  autoComplete?: string;
  className?: string;
  postfix?: string;
  tabIndex?: number;
}

// eslint-disable-next-line react/display-name
const Input: React.FC<InputProps> = forwardRef(
  (
    {
      type = 'input',
      value,
      placeholder,
      right,
      error,
      mask,
      disabled,
      autoComplete,
      onChange,
      className,
      postfix = '',
      tabIndex,
      ...props
    },
    ref
  ) => (
    <div className={css.Root}>
      {mask ? (
        <InputMask
          ref={ref}
          mask={mask}
          type={type}
          className={error ? css.InputError : css.Input}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      ) : (
        <input
          ref={ref}
          tabIndex={tabIndex}
          type={type}
          className={cx(error ? css.InputError : css.Input, className)}
          value={`${value}${postfix}`}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
          onChange={(e) => onChange(e.currentTarget.value)}
          {...props}
        />
      )}
      {right && <div className={css.Right}>{right}</div>}
      {error && <p className={css.Error}>{error}</p>}
    </div>
  )
);

export default Input;
