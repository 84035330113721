import React, { ReactNode } from 'react';

import { Flex, FlexProps } from 'ui/flex';

import { useMediaQuery } from '../../hooks/use-media-query.hook';

interface ResponsiveFlexProps extends FlexProps {
  children: ReactNode;
  mobile?: Omit<FlexProps, 'children'>;
  tablet?: Omit<FlexProps, 'children'>;
}

export const ResponsiveFlex: React.FC<ResponsiveFlexProps> = ({
  children,
  mobile,
  tablet,
  ...flexArguments
}) => {
  const isMobile = useMediaQuery('(max-width: 430px)');
  const isTablet = useMediaQuery('(max-width: 834px)');

  let flexProps = {
    ...flexArguments,
  };

  if (isMobile) {
    flexProps = {
      ...flexProps,
      ...mobile,
    };
  }

  if (isTablet) {
    flexProps = {
      ...flexProps,
      ...tablet,
    };
  }

  return <Flex {...flexProps}>{children}</Flex>;
};
