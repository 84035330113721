import cx from 'classnames';
import React, { MutableRefObject, forwardRef } from 'react';

import css from './card.module.scss';

type CardProps = {
  className?: string;
  header?: string;
  children: React.ReactNode;
  onClick?: () => void;
};

// eslint-disable-next-line react/display-name
const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ className, onClick, header, children }, ref) => {
    return (
      <div className={cx(css.Root, className)} onClick={onClick} ref={ref}>
        {children}
      </div>
    );
  }
);

export default Card;
