import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { namespace } from './namespace';
import { resources } from './resources';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'ru',
    defaultNS: 'common',
    ns: namespace,
    supportedLngs: ['en', 'ru'],
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      cookieOptions: {
        path: '/',
        sameSite: 'strict',
        secure: true,
        maxAge: 2592000, // maxAge 30 days
      },
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
    },
  });

export default i18n;
