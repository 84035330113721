import { type FirebaseApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const getPushToken = async (firebaseApp: FirebaseApp) => {
  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    const permission = Notification.permission;

    if (permission === 'granted') {
      const messaging = getMessaging(firebaseApp);

      const currentToken = await getToken(messaging);
      if (currentToken) {
        return currentToken;
      } else {
        throw new Error(
          'No registration token available. Request permission to generate one.'
        );
      }
    }
  }

  return null;
};

export default getPushToken;
