type ListenerFunc = (...args: any[]) => void;

export const enum EmitterEvents {
  CREATE_NEWS_MODAL_CLOSE = 'createNewsModalClose',
  NEWS_COMMENTS_CHANGE = 'newsCommentsChange',
}

class EventEmitter {
  private events: Record<string, ListenerFunc[]> = {};

  subscribeOnEvent(event: string, listener: ListenerFunc) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  }

  unsubscribeOnEvent(event: string, listener: ListenerFunc) {
    if (!this.events[event]) {
      return;
    }
    this.events[event] = this.events[event].filter((l) => l !== listener);
  }

  private emit(event: string, ...args: any[]) {
    if (!this.events[event]) {
      return;
    }
    this.events[event].forEach((listener) => listener(...args));
  }

  emitCreateNewsModalClose() {
    this.emit(EmitterEvents.CREATE_NEWS_MODAL_CLOSE);
  }

  emitNewsCommentsChange() {
    this.emit(EmitterEvents.NEWS_COMMENTS_CHANGE);
  }
}

export default EventEmitter;
