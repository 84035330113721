const LocalStorage = {
  get: (key: string) => {
    if (!localStorage) {
      return null;
    }

    return localStorage.getItem(key);
  },
  set: (key: string, data: string) => {
    if (!localStorage) {
      return;
    }

    localStorage.setItem(key, data);
  },
  remove: (key: string) => {
    if (!localStorage) {
      return;
    }

    localStorage.removeItem(key);
  },
};

export default LocalStorage;
