import cx from 'classnames';
import React from 'react';

import { Button } from 'ui';
import { Flex } from 'ui/flex';
import { Like } from 'ui/icons';

import css from './likes.module.scss';

interface NewsLikesProps {
  likesCount: number | string;
  isLiked: boolean;
  setLike: () => void;
}

const Likes: React.FC<NewsLikesProps> = ({ likesCount, isLiked, setLike }) => (
  <button onClick={setLike} className={cx(css.Root, isLiked && css.Active)}>
    <span className={css.LikesCount}>{likesCount}</span>
    <Like width={15} height={15} />
  </button>
);

export default Likes;
