import React from 'react';

import css from './toast.module.scss';

interface ToastProps {
  title: string;
  body?: string;
}

const Toast: React.FC<ToastProps> = ({ title, body }) => {
  return (
    <>
      <div>{title}</div>
      {body && <div className={css.Body}>{body}</div>}
    </>
  );
};

export default Toast;
