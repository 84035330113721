import cx from 'classnames';
import React from 'react';

import css from './slider.module.scss';

interface SliderProps {
  value: number;
  min: number;
  max: number;
  disabled?: boolean;
  className?: string;
  onChange: (number: number) => void;
}

const Slider: React.FC<SliderProps> = ({
  value,
  min,
  max,
  onChange,
  disabled,
  className,
}) => {
  const percent = Math.round(((value - min) / (max - min)) * 100);

  return (
    <div className={css.Root}>
      <input
        disabled={disabled}
        type="range"
        value={value}
        min={min}
        max={max}
        onTouchMoveCapture={(e) => e.stopPropagation()}
        onPointerMoveCapture={(e) => e.stopPropagation()}
        onChange={(e) => onChange(parseFloat(e.currentTarget.value))}
        className={cx(css.Slider, className)}
        style={{
          background: `linear-gradient(to right, #FF5757 0%, #FF5757 ${percent}%, #eeeeee ${percent}%, #eeeeee 100%)`,
        }}
      />
    </div>
  );
};

export default Slider;
