import { useEffect, useState } from 'react';

import useMounted from './use-mounted';

const MOBILE_WIDTH = 420;

const getWindowDimensions = () => {
  if (typeof window === 'undefined') {
    return {
      width: 9999, // Чтобы дефолтно был десктоп
      isMobile: false,
    };
  }

  const { innerWidth: width } = window;

  return {
    width,
    isMobile: width < MOBILE_WIDTH,
  };
};

const useWindowDimensions = () => {
  const mounted = useMounted();

  const [windowDimensions, setWindowDimensions] = useState({
    mounted,
    ...getWindowDimensions(),
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ mounted, ...getWindowDimensions() });
    };

    window.addEventListener('resize', handleResize);

    setWindowDimensions({ mounted, ...getWindowDimensions() });

    return () => window.removeEventListener('resize', handleResize);
  }, [mounted]);

  return windowDimensions;
};

export default useWindowDimensions;
