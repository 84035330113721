import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface TwoFactorConfirmSigninMutation {
  twoFactorConfirmSignin:
    | {
        __typename: 'TwoFactorConfirmSignin';
        redirectUrl: string;
      }
    | GraphqlErrorType;
}

export interface TwoFactorConfirmSigninMutationVars {
  confirmState: string;
  emailCode: string;
}

const TWO_FACTOR_CONFIRM_SIGNIN = gql`
  mutation TwoFactorConfirmSignin($emailCode: String!, $confirmState: String!) {
    twoFactorConfirmSignin(emailCode: $emailCode, confirmState: $confirmState) {
      __typename
      ... on TwoFactorConfirmSignin {
        client {
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default TWO_FACTOR_CONFIRM_SIGNIN;
