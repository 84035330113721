import cx from 'classnames';
import Link from 'next/link';
import React from 'react';

import ArrowIcon from 'ui/icons/arrow-down.svg';

import css from './widget-header.module.scss';

interface WidgetHeaderProps {
  href: string;
  title: string;
  withArrow?: boolean;
  NoBorderBottom?: boolean;
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  href,
  title,
  withArrow,
  NoBorderBottom,
}) => {
  return (
    <Link
      href={href}
      className={cx(css.Root, {
        [css.NoBorder]: NoBorderBottom,
        [css.NoClick]: !href,
      })}
    >
      {title}
      {withArrow && <ArrowIcon className={css.Arrow} />}
    </Link>
  );
};

export default WidgetHeader;
