import { useRouter } from 'next/router';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

import WebApp from 'application/web-app';
import ForgotPasswordModal from 'features/forgot-password/modals/forgot-password-modal';
import SignInModal from 'features/signin/modals/signin-modal';
import SignUpModal from 'features/signup/modals/signup-modal';

type AppModals = {
  isSignInOpen: boolean;
  setSignInOpen: (open: boolean) => void;
  isSignUpOpen: boolean;
  setSignUpOpen: (open: boolean) => void;
  isForgotPasswordOpen: boolean;
  setForgotPasswordOpen: (open: boolean) => void;
};

const AppModalsContext = createContext<AppModals>({
  isSignInOpen: false,
  isSignUpOpen: false,
  isForgotPasswordOpen: false,
  setSignInOpen: () => {},
  setSignUpOpen: () => {},
  setForgotPasswordOpen: () => {},
});

export const useAppModals = () => useContext(AppModalsContext);

const AppModalsProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();

  const [isSignInOpen, setSignInOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  return (
    <AppModalsContext.Provider
      value={{
        isSignInOpen,
        isSignUpOpen,
        isForgotPasswordOpen,
        setSignInOpen,
        setSignUpOpen,
        setForgotPasswordOpen,
      }}
    >
      {children}
      <SignInModal
        isOpen={isSignInOpen}
        onClose={() => setSignInOpen(false)}
        onGotToSignup={() => {
          setSignInOpen(false);
          setSignUpOpen(true);
        }}
        onGoToForgotPassword={() => {
          setSignInOpen(false);
          setForgotPasswordOpen(true);
        }}
      />
      <SignUpModal
        isOpen={isSignUpOpen}
        onClose={() => setSignUpOpen(false)}
        onSignup={() => {
          WebApp.analytic.sendSignupEvent({ type: 'email' });
          setSignUpOpen(false);
          router.push('/registration-confirm');
        }}
        onGoToSignin={() => {
          setSignUpOpen(false);
          setSignInOpen(true);
        }}
      />
      <ForgotPasswordModal
        isOpen={isForgotPasswordOpen}
        onClose={() => setForgotPasswordOpen(false)}
        onGoToSignIn={() => {
          setForgotPasswordOpen(false);
          setSignInOpen(true);
        }}
      />
    </AppModalsContext.Provider>
  );
};

export default AppModalsProvider;
