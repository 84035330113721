import { useEffect, useState } from 'react';

import webApp from 'application/web-app';
import { useFirebaseApp } from 'features/firebase/context/firebase-provider';

const FIREBASE_MODAL_KEY = 'push-modal';

const useFirebaseModalState = () => {
  const { token } = useFirebaseApp();

  const [firebaseModalState, setFirebaseModalState] = useState(false);

  useEffect(() => {
    if (token === undefined) {
      return;
    }

    if (token) {
      setFirebaseModalState(false);
    } else {
      setFirebaseModalState(
        webApp.localStorage.get(FIREBASE_MODAL_KEY) ? false : true
      );
    }
  }, [token]);

  return [
    firebaseModalState,
    (value: boolean) => {
      if (value) {
        webApp.localStorage.remove(FIREBASE_MODAL_KEY);
      } else {
        webApp.localStorage.set(FIREBASE_MODAL_KEY, '1');
      }

      setFirebaseModalState(value);
    },
  ] as const;
};

export default useFirebaseModalState;
