export enum GridGap {
  x0 = '0px',
  x1 = '8px',
  x2 = '16px',
  x3 = '24px',
  x4 = '32px',
  x5 = '40px',
  x6 = '48px',
  x7 = '56px',
  x8 = '64px',
}
