import cx from 'classnames';
import React, { ReactNode } from 'react';

import css from './button.module.scss';

interface ButtonProps {
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'invert' | 'empty';
  onClick?: () => void;
  disabled?: boolean;
  outline?: boolean;
  filled?: boolean;
  className?: string;
  relative?: boolean;
  full?: boolean;
  icon?: ReactNode;
  children?: React.ReactNode;
  tabIndex?: number;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  size = 'medium',
  color = 'primary',
  outline,
  filled,
  disabled,
  relative,
  className,
  children,
  icon,
  full,
  tabIndex,
}) => (
  <button
    className={cx(
      css.Button,
      className,
      size === 'large' && css.BigButton,
      size === 'small' && css.SmallButton,
      color === 'primary' &&
        (outline ? css.PrimaryOutlineButton : css.PrimaryButton),
      color === 'secondary' &&
        (outline ? css.SecondaryOutlineButton : css.SecondaryButton),
      color === 'invert' && css.InvertButton,
      color === 'empty' && css.EmptyButton,
      filled && outline && color === 'primary' && css.PrimaryFilled,
      filled && outline && color === 'secondary' && css.SecondaryFilled,
      full && css.Full,
      relative && css.Relative
    )}
    onClick={onClick}
    disabled={disabled}
    tabIndex={tabIndex}
  >
    {icon}
    {children}
  </button>
);

export default Button;
