export const namespace = [
  'account',
  'affiliate',
  'announcements',
  'auth',
  'bets',
  'comments',
  'common',
  'dropzone',
  'events',
  'footer',
  'header',
  'help',
  'live_orders',
  'news',
  'notification',
  'period',
  'settings',
  'seo',
  'tournaments',
  'verifications',
  'vote',
  'wallets',
];
