import cx from 'classnames';
import React from 'react';

import css from './index-card.module.scss';

interface SmallCardProps {
  small?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
}

const IndexCard: React.FC<SmallCardProps> = ({
  small = false,
  noPadding = false,
  children,
}) => (
  <div
    className={cx(css.Root, {
      [css.SmallRoot]: small,
      [css.NoPaddingRoot]: noPadding,
    })}
  >
    {children}
  </div>
);

export default IndexCard;
