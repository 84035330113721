import cx from 'classnames';
import React from 'react';

import BookmarkIcon from 'assets/icons/bookmark.svg';
import { Button } from 'ui';

import css from './bookmark.module.scss';
import { BookmarksInfoType } from '../../features/news/graphql/update-bookmarks-news';

interface BookmarkProps {
  bookmarksInfo: BookmarksInfoType;
  addToBookmarks: () => void;
}

const Bookmark: React.FC<BookmarkProps> = ({
  bookmarksInfo,
  addToBookmarks,
}) => {
  return (
    <div className={css.Root}>
      <Button
        className={cx(
          css.Button,
          bookmarksInfo.inBookmarks && css.ButtonActive
        )}
        color="empty"
        onClick={addToBookmarks}
      >
        <BookmarkIcon width={30} height={30} />
      </Button>
    </div>
  );
};

export default Bookmark;
