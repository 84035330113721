import cx from 'classnames';
import React from 'react';

import css from './hidden.module.scss';

interface HiddenProps {
  desktop?: boolean;
  mobile?: boolean;
  children: React.ReactNode;
}

const Hidden: React.FC<HiddenProps> = ({ desktop, mobile, children }) => {
  return (
    <div className={cx(desktop && css.Desktop, mobile && css.Mobile)}>
      {children}
    </div>
  );
};

export default Hidden;
