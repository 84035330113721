'use client'; // This is a client component 👈🏽

import { MetaMaskSDK, SDKProvider } from '@metamask/sdk';
import { EventType } from '@metamask/sdk-communication-layer';
import { BrowserProvider, Contract, formatEther, parseEther } from 'ethers';
import { createContext, useContext, useEffect, useState } from 'react';

import tokenAbi from '../token-abi.json';

const AppMetaMaskContext = createContext<{
  sdk: MetaMaskSDK | null;
  connected: boolean;
  account: string;
  sign: (msg: string) => Promise<string>;
  approve: (amount: number) => Promise<void>;
  revoke: () => Promise<void>;
  getBalance: (account: string) => Promise<number>;
  connect: () => Promise<void>;
  disconnect: () => void;
}>({
  sdk: null,
  account: '',
  connected: false,
  sign: () => {
    throw new Error('Sign not implememnted');
  },
  approve: () => {
    throw new Error('Approve not implememnted');
  },
  revoke: () => {
    throw new Error('Revoke not implememnted');
  },
  getBalance: () => {
    throw new Error('GetBalance not implememnted');
  },
  connect: () => {
    throw new Error('Connect not implememnted');
  },
  disconnect: () => {
    throw new Error('Disconnect not implememnted');
  },
});

export const useAppMetamask = () => useContext(AppMetaMaskContext);

const AppMetaMaskProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sdk, setSDK] = useState<MetaMaskSDK | null>(null);
  const [activeProvider, setActiveProvider] = useState<SDKProvider>();
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState<string>('');

  useEffect(() => {
    const doAsync = async () => {
      const clientSDK = new MetaMaskSDK({
        checkInstallationImmediately: false,
        dappMetadata: {
          name: 'Votesport.io',
          url: window.location.href,
        },
        storage: {
          enabled: true,
        },
      });
      await clientSDK.init();
      setSDK(clientSDK);
    };
    doAsync();
  }, []);

  useEffect(() => {
    if (!sdk?.isInitialized()) {
      return;
    }

    const onProviderEvent = (accounts?: string[]) => {
      if (accounts?.[0]?.startsWith('0x')) {
        setConnected(true);
        setAccount(accounts?.[0]);
      } else {
        setConnected(false);
        setAccount('');
      }

      setActiveProvider(sdk.getProvider());
    };

    sdk.on(EventType.PROVIDER_UPDATE, onProviderEvent);
    return () => {
      sdk.removeListener(EventType.PROVIDER_UPDATE, onProviderEvent);
    };
  }, [sdk]);

  useEffect(() => {
    if (!sdk || !activeProvider) {
      return;
    }

    if (window.ethereum?.selectedAddress) {
      setAccount(window.ethereum?.selectedAddress);
      setConnected(true);
    } else {
      setConnected(false);
    }

    const onInitialized = () => {
      setConnected(true);

      if (window.ethereum?.selectedAddress) {
        setAccount(window.ethereum?.selectedAddress);
      }
    };

    const onAccountsChanged = (accounts: unknown) => {
      setAccount((accounts as string[])?.[0]);
      setConnected(true);
    };

    const onConnect = (_connectInfo: any) => {
      setConnected(true);
    };

    const onDisconnect = (error: unknown) => {
      setConnected(false);
    };

    window.ethereum?.on('_initialized', onInitialized);
    window.ethereum?.on('accountsChanged', onAccountsChanged);
    window.ethereum?.on('connect', onConnect);
    window.ethereum?.on('disconnect', onDisconnect);

    return () => {
      window.ethereum?.removeListener('_initialized', onInitialized);
      window.ethereum?.removeListener('connect', onConnect);
      window.ethereum?.removeListener('disconnect', onDisconnect);
    };
  }, [sdk, activeProvider]);

  return (
    <AppMetaMaskContext.Provider
      value={{
        sdk,
        connected,
        account,
        sign: async (message: string) => {
          const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
          const sign = (await window.ethereum?.request({
            method: 'personal_sign',
            params: [msg, account],
          })) as string;

          return sign || '';
        },
        approve: async (amount: number) => {
          if (!window.ethereum) {
            throw new Error(`invalid ethereum provider`);
          }

          const tokenAddress = process.env.NEXT_PUBLIC_TOKEN_ADDRESS;
          if (!tokenAddress) {
            throw new Error('Wrong token address');
          }

          const contractAddress = process.env.NEXT_PUBLIC_CONTRACT_ADDRESS;
          if (!contractAddress) {
            throw new Error('Wrong contract address');
          }

          const provider = new BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();

          const tokenContract = new Contract(tokenAddress, tokenAbi, signer);

          try {
            await tokenContract.approveVote(
              account,
              parseEther(amount.toString())
            );
          } catch (e: unknown) {}
        },
        revoke: async () => {
          if (!window.ethereum) {
            throw new Error(`invalid ethereum provider`);
          }

          const tokenAddress = process.env.NEXT_PUBLIC_TOKEN_ADDRESS;
          if (!tokenAddress) {
            throw new Error('Wrong token address');
          }

          const contractAddress = process.env.NEXT_PUBLIC_CONTRACT_ADDRESS;
          if (!contractAddress) {
            throw new Error('Wrong contract address');
          }

          const provider = new BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();

          const tokenContract = new Contract(tokenAddress, tokenAbi, signer);

          try {
            await tokenContract.revokeApproval(contractAddress);
          } catch (e: unknown) {}
        },
        getBalance: async (account) => {
          if (!window.ethereum) {
            throw new Error(`invalid ethereum provider`);
          }

          const tokenAddress = process.env.NEXT_PUBLIC_TOKEN_ADDRESS;
          if (!tokenAddress) {
            throw new Error('Wrong token address');
          }

          const contractAddress = process.env.NEXT_PUBLIC_CONTRACT_ADDRESS;
          if (!contractAddress) {
            throw new Error('Wrong contract address');
          }

          const provider = new BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();

          const tokenContract = new Contract(tokenAddress, tokenAbi, signer);

          try {
            const result = await tokenContract
              .allowance(account, contractAddress)
              .then((res) => parseFloat(formatEther(res)));
            console.log({ result });
            return result;
          } catch (e: unknown) {
            console.log(e);
            return 0;
          }
        },
        connect: async () => {
          try {
            if (!window.ethereum) {
              throw new Error(`invalid ethereum provider`);
            }

            window.ethereum
              .request({
                method: 'eth_requestAccounts',
                params: [],
              })
              .then((accounts) => {
                if (accounts) {
                  console.debug(`connect:: accounts result`, accounts);
                  setConnected(true);
                }
              })
              .catch((e) => console.log('request accounts ERR', e));
          } catch (err) {
            console.log(err);
          }
        },
        disconnect: () => {
          sdk?.terminate();
        },
      }}
    >
      {children}
    </AppMetaMaskContext.Provider>
  );
};

export default AppMetaMaskProvider;
