import { ApolloProvider } from '@apollo/client';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect } from 'react';

import config from 'application/config';
import AppConfigProvider from 'application/context/app-config-provider';
import AppModalsProvider from 'application/context/app-modals-provider';
import UserProvider from 'application/context/user-provider';
import { useApollo } from 'application/graphql/apollo-client';
import BrowserChecker from 'features/browser-checker';
import useCookieTerms from 'features/cookie-terms';
import useDropNotification from 'features/drop-info-notification';
import FirebaseProvider from 'features/firebase/context/firebase-provider';
import PushTokenPermissionModalContainer from 'features/firebase/modals/push-token-permission-modal';
import AppMetaMaskProvider from 'features/wallets/context/app-metamask-context';
import { LoaderLinear } from 'ui';
import { AppToastContainer } from 'ui/toast';

import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/globals.scss';
import '../styles/toastify.scss';
import '../styles/history-fights.scss';
import '../styles/info-fighter.scss';
import '../styles/rating.scss';
import '../styles/statistics-fights.scss';
import '../styles/vertical-scroll-wrapper.scss';
import 'i18n/i18n-config';

export type AppPage<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type Props = AppProps & {
  Component: AppPage;
};

const NextApp = ({ Component, pageProps }: Props) => {
  useDropNotification();
  useCookieTerms();

  const router = useRouter();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
    };

    const handleRouteChangeComplete = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  const apolloClient = useApollo(
    { apiUrl: config.graphqlApiUrl, wssUrl: config.graphqlWsUrl },
    pageProps
  );

  const getLayout = Component.getLayout ?? ((page) => page);

  const App = getLayout(<Component {...pageProps} />);

  return (
    <>
      {config.gaId && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${config.gaId}`}
          />
          <Script id="" strategy="lazyOnload">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${config.gaId}', {
              page_path: window.location.pathname,
              });
          `}
          </Script>
        </>
      )}

      <ApolloProvider client={apolloClient}>
        <AppConfigProvider>
          <AppModalsProvider>
            <FirebaseProvider>
              <UserProvider>
                <AppMetaMaskProvider>
                  {loading && <LoaderLinear />}
                  {App}
                  <BrowserChecker />
                  <AppToastContainer />
                  <PushTokenPermissionModalContainer />
                </AppMetaMaskProvider>
              </UserProvider>
            </FirebaseProvider>
          </AppModalsProvider>
        </AppConfigProvider>
      </ApolloProvider>
    </>
  );
};

export default NextApp;
