import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface SignupClientMutation {
  signupClient:
    | {
        __typename: 'SignupClient';
        client: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface SignupClientMutationVars {
  input: {
    username: string;
    email: string;
    password: string;
    recaptcha: string | null;
    refCode?: string;
    agreement: boolean;
  };
}

const SIGNUP_CLIENT = gql`
  mutation SignupClient($input: SignupClientInput!) {
    signupClient(input: $input) {
      __typename
      ... on SignupClient {
        client {
          id
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default SIGNUP_CLIENT;
