import React, { ReactNode, useState } from 'react';

import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'ui/icons';
import { Text } from 'ui/v2/typography/text';

import css from './checkbox.module.scss';

interface CheckboxProps {
  checked: boolean;
  onCheck: () => void;
  error?: string;
  text?: string;
  background?: string;
  children?: ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onCheck,
  text = '',
  error,
  background = '#2873DB',
  children,
}) => {
  return (
    <Grid
      gap={GridGap.x1}
      gridTemplateColumns="24px 1fr"
      alignItems="top"
      className={css.Checkbox}
    >
      <div onClick={onCheck}>
        {checked ? (
          <CheckboxCheckedIcon color={background} />
        ) : (
          <CheckboxUncheckedIcon />
        )}
      </div>
      {children || <Text type="paragraph">{text}</Text>}
      {error && <div className={css.Error}>{error}</div>}
    </Grid>
  );
};

export default Checkbox;
