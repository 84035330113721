import Link from 'next/link';
import React, { useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { Id, toast } from 'react-toastify';

import InfoIcon from 'ui/icons/info';
import { Text } from 'ui/v2/typography/text';

import css from './drop-info-notification.module.scss';

interface Props {
  onLinkClick: () => void;
}

const NotificationContent = ({ onLinkClick }: Props) => {
  return (
    <Text type="caption" color="white-color">
      <Trans
        i18nKey="notifications:drops_notification"
        components={{
          1: (
            <Link
              href={{ pathname: '/', query: { 'how-vote': '', drop: '' } }}
              className={css.Link}
              onClick={onLinkClick}
            />
          ),
        }}
      />
    </Text>
  );
};

const dropNotification = ({ onLinkClick }: Props) =>
  toast.info(<NotificationContent onLinkClick={onLinkClick} />, {
    icon: <InfoIcon />,
    autoClose: false,
    closeOnClick: false,
    onClose: onLinkClick,
  });

export const useDropNotification = () => {
  const toastId = useRef<Id | null>(null);

  const onLinkClick = () => {
    localStorage.setItem('vote-sport-drop-notification', '1');

    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
  };

  useEffect(() => {
    const dropNotificationCalled = localStorage.getItem(
      'vote-sport-drop-notification'
    );

    if (!dropNotificationCalled) {
      toastId.current = dropNotification({ onLinkClick });
    }
  }, []);
};

export default useDropNotification;
