import { OutputBlockData } from '@editorjs/editorjs/types/data-formats/output-data';

export type EditorBlockDataType =
  | { text: string }
  | { items: string[]; style: 'unordered' | 'ordered' }
  | { text: string; level: number }
  | { text: string; caption: string; alignment: string }
  | {
      file: { url: string };
      caption: string;
      stretched: boolean;
    }
  | {
      url: string;
      serviceName: string;
      html: string;
    };

export type EditorBlockType = OutputBlockData<string, EditorBlockDataType>;

export type EditorBlocksType = EditorBlockType[];

export const enum WidgetServices {
  VK_VIDEO = 'VK',
  TWITTER = 'Twitter',
  YOUTUBE = 'YouTube',
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
}

export const enum WidgetServicesScriptSrc {
  INSTAGRAM = 'https://www.instagram.com/embed.js',
  TWITTER = 'https://platform.twitter.com/widgets.js',
}
