import cx from 'classnames';
import React, { useEffect, useRef } from 'react';

import css from './textarea.module.scss';

interface TextareaProps {
  value: string;
  onChange: (value: string) => void;
  height?: number;
  buttons?: React.ReactNode;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: string;
}

const Textarea: React.FC<TextareaProps> = ({
  value,
  onChange,
  height,
  buttons,
  autoFocus,
  disabled,
  error,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoFocus && textareaRef.current) {
      textareaRef.current.focus();
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }, []);

  return (
    <div className={css.Root} data-ui-component="styled-textarea">
      <textarea
        ref={textareaRef}
        value={value}
        autoFocus={autoFocus ?? false}
        className={cx(css.Textarea, error && css.TextareaError)}
        onChange={(e) => onChange(e.currentTarget.value)}
        style={{ height: height ? `${height}px` : undefined }}
        disabled={disabled}
      />
      {buttons && <div className={css.Buttons}>{buttons}</div>}
      {error && <p className={css.Error}>{error}</p>}
    </div>
  );
};

export default Textarea;
