import React from 'react';
import { LineWave } from 'react-loader-spinner';

import css from './loader.module.scss';

const Loader: React.FC = () => {
  return (
    <LineWave
      height="100"
      width="100"
      color="#db2828"
      ariaLabel="line-wave"
      wrapperStyle={{}}
      wrapperClass={css.Root}
      visible={true}
      firstLineColor=""
      middleLineColor=""
      lastLineColor=""
    />
  );
};

export default Loader;
