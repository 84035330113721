import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { ArrowDownIcon } from 'ui/icons';

import css from './dropdown-button.module.scss';

interface DropdownButtonProps<T> {
  value: T;
  items: { value: T; label: string }[];
  disabled?: boolean;
  onChange: (value: T) => void;
  classNameLabel?: string;
  className?: string;
}

type DropdownButtonFC = <T>(
  props: DropdownButtonProps<T>
) => React.ReactElement<DropdownButtonProps<T>>;

const DropdownButton: DropdownButtonFC = ({
  value,
  items,
  disabled,
  onChange,
  className,
  classNameLabel,
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handler);
    }

    return () => document.removeEventListener('click', handler);
  }, [isOpen]);

  return (
    <div className={cx(css.Root, className)}>
      <button
        className={disabled ? css.ButtonDisabled : css.Button}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
      >
        <div className={cx(css.Label, classNameLabel)}>
          {items.find((item) => item.value === value)?.label}
        </div>
        {items && <ArrowDownIcon className={css.ArrowIcon} />}
      </button>

      {isOpen && (
        <ul className={css.List} ref={ref}>
          {items.map((item, index) => (
            <li
              key={index}
              className={item.value === value ? css.ItemActive : css.Item}
              onClick={() => {
                onChange(item.value);
                setOpen(false);
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownButton;
