import React from 'react';

import css from './card-content.module.scss';

interface CardContent {
  children: React.ReactNode;
  noVertical?: boolean;
}

const CardContent: React.FC<CardContent> = ({ children, noVertical }) => {
  return (
    <div className={noVertical ? css.WithoutVertical : css.Root}>
      {children}
    </div>
  );
};

export default CardContent;
