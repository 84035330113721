import BandagedHandIcon from '../assets/icons/challenge/bandaged-hand.svg';
import BellIcon from '../assets/icons/challenge/bell.svg';
import GlovesTouchingIcon from '../assets/icons/challenge/gloves-touching.svg';
import PunchBagIcon from '../assets/icons/challenge/punch-bag.svg';
import RobeIcon from '../assets/icons/challenge/robe.svg';
import RoundIcon from '../assets/icons/challenge/round.svg';
import TicketIcon from '../assets/icons/challenge/ticket.svg';
import TitleBeltIcon from '../assets/icons/challenge/title-belt.svg';
import WorldTitleIcon from '../assets/icons/challenge/world-title.svg';

export {
  BandagedHandIcon,
  BellIcon,
  GlovesTouchingIcon,
  PunchBagIcon,
  RobeIcon,
  RoundIcon,
  TicketIcon,
  TitleBeltIcon,
  WorldTitleIcon,
};
