import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface UpdatePushTokenMutation {
  updatePushToken:
    | {
        __typename: 'UpdatePushToken';
        client: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface UpdatePushTokenMutationVars {
  token: string;
}

const UPDATE_PUSH_TOKEN = gql`
  mutation UpdatePushToken($token: String!) {
    updatePushToken(token: $token) {
      __typename
      ... on UpdatePushToken {
        client {
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default UPDATE_PUSH_TOKEN;
