import React from 'react';

import { Button } from 'ui';
import { Flex } from 'ui/flex';

import css from './comments.module.scss';
import CommentsIcon from '../../assets/icons/comment.svg';

interface NewsCommentsProps {
  commentsCount?: number | string;
  showComments?: () => void;
}

const Comments: React.FC<NewsCommentsProps> = ({
  commentsCount = 0,
  showComments,
}) => {
  return (
    <Flex alignItems="center" style={{ columnGap: '3px' }}>
      <span className={css.CommentsCount}>{commentsCount}</span>
      <Button className={css.Button} color="empty" onClick={showComments}>
        <CommentsIcon width={20} height={20} />
      </Button>
    </Flex>
  );
};

export default Comments;
