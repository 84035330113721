import cx from 'classnames';
import React, { CSSProperties } from 'react';

import css from './text.module.scss';
import { useMediaQuery } from '../../../hooks/use-media-query.hook';

type TextColor =
  | 'black-primary'
  | 'white-color'
  | 'grey-primary-3'
  | 'grey-primary-4'
  | 'grey-primary-7'
  | 'grey-primary'
  | 'green-primary'
  | 'positive-color'
  | 'negative-color'
  | 'blue-primary'
  | 'red-primary';

type TextType =
  | 'paragraph'
  | 'headline'
  | 'modal-title'
  | 'link'
  | 'body'
  | 'body2'
  | 'body3'
  | 'caption'
  | 'caption2'
  | 'caption3'
  | 'tagline';

interface TextProps extends Record<string, any> {
  children?: React.ReactNode;
  type: TextType;
  bold?: boolean;
  strong?: boolean;
  uppercase?: boolean;
  color?: TextColor;
  opacity?: number;
  textAlign?: 'right' | 'left' | 'center';
  noWrap?: boolean;
  ellipsis?: boolean;
  mobile?: Pick<CSSProperties, 'fontSize'>;
  tablet?: Pick<CSSProperties, 'fontSize'>;
  className?: string;
  onClick?: () => void;
}

export const Text: React.FC<TextProps> = ({
  children,
  type = 'paragraph',
  bold,
  strong,
  uppercase,
  color,
  opacity = 1,
  textAlign = 'left',
  noWrap = false,
  ellipsis = false,
  onClick = () => null,
  mobile,
  tablet,
  className,
  ...args
}) => {
  const isMobile = useMediaQuery('(max-width: 430px)');
  const isTablet = useMediaQuery('(max-width: 834px)');

  const cssTypes: Record<TextProps['type'], string> = {
    body: css.body,
    body2: css.body2,
    body3: css.body3,
    paragraph: css.paragraph,
    headline: css.headline,
    link: css.link,
    caption: css.caption,
    caption2: css.caption2,
    caption3: css.caption3,
    tagline: css.tagline,
    'modal-title': css['modal-title'],
  };

  const cssColors: Record<TextColor, string> = {
    'black-primary': css['black-primary'],
    'grey-primary': css['grey-primary'],
    'grey-primary-3': css['grey-primary-3'],
    'grey-primary-4': css['grey-primary-4'],
    'grey-primary-7': css['grey-primary-7'],
    'positive-color': css['positive-color'],
    'negative-color': css['negative-color'],
    'blue-primary': css['blue-primary'],
    'red-primary': css['red-primary'],
    'green-primary': css['green-primary'],
    'white-color': css['white-color'],
  };

  const styles = {
    ...(bold ? { fontWeight: 600 } : null),
    ...(strong ? { fontWeight: 500 } : null),
    ...(opacity ? { opacity } : null),
    ...(textAlign ? { textAlign } : null),
    ...(noWrap ? { whiteSpace: 'nowrap' } : null),
    ...(ellipsis ? { overflow: 'hidden', 'text-overflow': 'ellipsis' } : null),
    ...(args.style ? args.style : null),
  };

  if (isTablet && tablet) {
    styles.fontSize = tablet?.fontSize;
  }

  if (isMobile && mobile) {
    styles.fontSize = mobile?.fontSize;
  }

  return (
    <span
      onClick={onClick}
      className={cx(
        cssTypes[type] || css.paragraph,
        color && cssColors[color],
        uppercase && css.Uppercase,
        className && className
      )}
      style={styles}
    >
      {children}
    </span>
  );
};
