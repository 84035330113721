import EventAbstract from './event-abstract';

export type SigninEventData = {
  type: 'email' | 'google';
};

class SigninEvent extends EventAbstract<SigninEventData> {
  eventName = 'signin';
}

export default SigninEvent;
