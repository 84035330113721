import cx from 'classnames';
import React from 'react';

import css from './space.module.scss';
import { useMediaQuery } from '../../hooks/use-media-query.hook';

interface MediaQueryContainerProps {
  size: number;
  mobileSize?: number;
  tabletSize?: number;
  className?: string;
}

export const Space: React.FC<MediaQueryContainerProps> = ({
  size,
  mobileSize,
  tabletSize,
  className,
}) => {
  const isMobile = useMediaQuery('(max-width: 430px)');
  const isTablet = useMediaQuery('(max-width: 834px)');

  let spaceProps = {
    height: size + 'px',
  };

  if (isTablet) {
    spaceProps = {
      height: tabletSize + 'px',
    };
  }

  if (isMobile) {
    spaceProps = {
      height: mobileSize + 'px',
    };
  }

  return <div className={cx(css.space, className)} style={spaceProps} />;
};
