import { useQuery } from '@apollo/client';
import React, { createContext, useContext, useEffect, useState } from 'react';

import GET_APP_CONFIG, {
  GetAppConfigQuery,
  GetAppConfigQueryVars,
  SportType,
} from 'application/graphql/get-app-config';

type AppConfig = {
  sports: SportType[];
  walletEnabled: boolean;
  referralEnabled: boolean;
};

const AppConfigContext = createContext<{
  appConfig: AppConfig | null;
}>({
  appConfig: {
    sports: [],
    walletEnabled: false,
    referralEnabled: false,
  },
});

export const useAppConfig = () => useContext(AppConfigContext);

const AppConfigProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [appConfig, setAppConfig] = useState<AppConfig | null>(null);

  const { data } = useQuery<GetAppConfigQuery, GetAppConfigQueryVars>(
    GET_APP_CONFIG
  );

  useEffect(() => {
    if (data?.getAppConfig) {
      setAppConfig(data.getAppConfig);
    } else {
      setAppConfig(null);
    }
  }, [data]);

  return (
    <AppConfigContext.Provider
      value={{
        appConfig,
      }}
    >
      {appConfig && children}
    </AppConfigContext.Provider>
  );
};

export default AppConfigProvider;
