import Link from 'next/link';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import webApp from 'application/web-app';
import { Input, Modal, ModalContent, ModalFooter, PasswordInput } from 'ui';
import Button from 'ui/button';
import GoogleIcon from 'ui/icons/google';

import css from './signin-modal.module.scss';

export type SigninFormErrors = {
  email?: string;
  password?: string;
};

interface SigninModalProps {
  errors: SigninFormErrors;
  isOpen: boolean;
  isProcessing: boolean;
  onClose?: () => void;
  onSignin: (data: { email: string; password: string }) => void;
  onGotToSignup: () => void;
  onGoToForgotPassword: () => void;
  noOverflow?: boolean;
  noClose?: boolean;
}

const SigninModal: React.FC<SigninModalProps> = ({
  errors,
  isOpen,
  isProcessing,
  noOverflow,
  onClose,
  onSignin,
  onGotToSignup,
  onGoToForgotPassword,
  noClose,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation(['auth']);

  return (
    <Modal
      title={t('auth:sign_in')}
      isOpen={isOpen}
      width={375}
      onClose={noClose ? undefined : onClose}
      noOverflow={noOverflow}
    >
      <ModalContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={css.Email}>
            <Input
              type="email"
              value={email}
              onChange={setEmail}
              placeholder="Email"
              error={errors.email}
              autoComplete="username"
            />
          </div>
          <div className={css.Password}>
            <PasswordInput
              value={password}
              onChange={setPassword}
              placeholder={t('auth:password')}
              error={errors.password}
              autoComplete="current-password"
            />
          </div>
          <div className={css.ForgotPassword}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onGoToForgotPassword();
              }}
            >
              {t('auth:forgot_password')}
            </a>
          </div>
          <div className={css.LoginButton}>
            <Button
              className={css.Button}
              disabled={isProcessing}
              onClick={() => onSignin({ email, password })}
              relative
            >
              {t('auth:to_sign_in')}
            </Button>

            <Link href={webApp.router.getGoogleAuth()}>
              <Button size="large" className={css.Google} color="invert">
                <GoogleIcon />
                {t('auth:to_sign_in_google')}
              </Button>
            </Link>
          </div>
        </form>
      </ModalContent>
      <ModalFooter>
        <Button
          className={css.RegistrationButton}
          onClick={onGotToSignup}
          color="secondary"
          outline
        >
          {t('auth:sign_up')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SigninModal;
