import { useRouter } from 'next/router';
import React, { createRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Checkbox, Input, PasswordInput, ReCaptcha } from 'ui';
import Modal, { ModalContent, ModalFooter } from 'ui/modal';
import { Text } from 'ui/v2/typography/text';

import css from './signup-modal.module.scss';

type RegistrationData = {
  username: string;
  email: string;
  password: string;
  passwordConfirm: string;
  recaptcha: ReCAPTCHA | null;
  refCode?: string;
  agreement: boolean;
};

export type SignupFormErrors = {
  username?: string;
  email?: string;
  password?: string;
  passwordConfirm?: string;
  agreement?: string;
  recaptcha?: string;
};

interface SignupModalProps {
  errors: SignupFormErrors;
  isOpen: boolean;
  isProcessing: boolean;
  noOverflow?: boolean;
  onClearPasswordConfirmError: () => void;
  onClose?: () => void;
  onRegistration: (data: RegistrationData) => void;
  onGotToSignin: () => void;
}

const SignupModal: React.FC<SignupModalProps> = ({
  errors,
  isOpen,
  isProcessing,
  noOverflow,
  onClose,
  onRegistration,
  onGotToSignin,
  onClearPasswordConfirmError,
}) => {
  const { query } = useRouter();

  const refCode = query.refCode as string | undefined;

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [agreement, setAgreement] = useState(false);
  const recaptcha = createRef<ReCAPTCHA>();
  const { t } = useTranslation(['auth']);

  return (
    <Modal
      title={t('auth:sign_up')}
      isOpen={isOpen}
      width={375}
      onClose={onClose}
      noOverflow={noOverflow}
    >
      <ModalContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className={css.Group}>
            <div className={css.Title}>
              <Trans
                i18nKey="auth:nick_example"
                components={{
                  1: <Text type="paragraph" color="grey-primary"></Text>,
                }}
              />
            </div>
            <div className={css.Input}>
              <Input
                value={username}
                onChange={setUsername}
                error={errors.username}
              />
            </div>
          </div>

          <div className={css.Group}>
            <div className={css.Title}>{t('auth:email')}</div>
            <div className={css.Input}>
              <Input
                type="email"
                value={email}
                onChange={setEmail}
                error={errors.email}
              />
            </div>
          </div>

          <div className={css.Group}>
            <div className={css.Title}>{t('auth:password')}</div>
            <div className={css.Input}>
              <PasswordInput
                value={password}
                onChange={setPassword}
                error={errors.password}
              />
            </div>
          </div>

          <div className={css.Group}>
            <div className={css.Title}>{t('auth:password_confirm')}</div>
            <div className={css.Input}>
              <PasswordInput
                value={passwordConfirm}
                onChange={setPasswordConfirm}
                error={errors.passwordConfirm}
                onFocus={onClearPasswordConfirmError}
              />
            </div>
          </div>

          <div className={css.Group}>
            <Checkbox
              checked={agreement}
              onCheck={() => {
                setAgreement(!agreement);
              }}
              error={errors.agreement}
            >
              <div className={css.Agreement}>
                {t('auth:agreement')}
                <a href="/privacy" target="_blank">
                  {t('auth:terms')}
                </a>
                .
              </div>
            </Checkbox>
          </div>

          <div className={css.Group}>
            <ReCaptcha ref={recaptcha} error={errors.recaptcha} />
          </div>

          <div className={css.Group}>
            <Button
              className={css.Button}
              disabled={isProcessing}
              onClick={() =>
                onRegistration({
                  username,
                  email,
                  password,
                  passwordConfirm,
                  agreement,
                  recaptcha: recaptcha.current ?? null,
                  refCode,
                })
              }
              relative
              color="secondary"
            >
              {t('auth:to_sign_up')}
            </Button>
          </div>
        </form>
      </ModalContent>
      <ModalFooter>
        <Button disabled={isProcessing} onClick={onGotToSignin} relative>
          {t('auth:sign_in')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SignupModal;
