import cx from 'classnames';
import React from 'react';

import css from './table.module.scss';

const TableHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={css.Header}>{children}</div>
);
const TableBody: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={css.Body}>{children}</div>
);
const TableRow: React.FC<{
  noLine?: boolean;
  className?: string;
  children: React.ReactNode;
}> = ({ noLine = false, className, children }) => (
  <div className={cx(noLine ? css.RowNoLine : css.Row, className)}>
    {children}
  </div>
);
const TableCol: React.FC<{
  width?: string;
  className?: string;
  noGrow?: boolean;
  children?: React.ReactNode;
}> = ({ width, className, noGrow = false, children }) => (
  <div
    className={cx(noGrow ? css.ColNoGrow : css.Col, className)}
    style={width ? { width: `${width}px`, flexShrink: '0' } : undefined}
  >
    {children}
  </div>
);

const Table: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div>{children}</div>
);

export { TableHeader, TableBody, TableRow, TableCol };
export default Table;
