import type EditorJS from '@editorjs/editorjs';
import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguage } from 'application/utils/get-language';
import type { LoadNewsWidgetLinkType } from 'features/news/types/load-news-widget-link-type';
import type { UploadType } from 'features/news/types/upload-type';
import { Toast } from 'ui';

import css from './editor-component.module.scss';
import { MAX_BLOCK_COUNT, editorI18n, editorTools } from './editor-constants';
import { EditorBlocksType } from './editor-types';

interface EditorProps {
  initBlocks: EditorBlocksType;
  blocksChange: (blocks: EditorBlocksType) => void;
  uploadImage?: UploadType;
  uploadVideo?: UploadType;
  loadWidgetLink?: LoadNewsWidgetLinkType;
}

const Editor: FC<EditorProps> = ({
  initBlocks,
  blocksChange,
  uploadImage,
  uploadVideo,
  loadWidgetLink,
}) => {
  const { t } = useTranslation(['common', 'editor']);
  const editorContainer = useRef<HTMLDivElement>(null);
  const editorInstance = useRef<EditorJS | null>(null);

  useEffect(() => {
    const initializeEditorInstance = async () => {
      if (!editorContainer.current || editorInstance.current) return;

      try {
        const EditorJSImport = await import('@editorjs/editorjs');
        const ImageTool = (await import('@editorjs/image')).default;
        const VideoTool = (await import('@weekwood/editorjs-video')).default;

        editorInstance.current = new EditorJSImport.default({
          holder: editorContainer.current,
          tools: editorTools(
            t,
            { image: ImageTool, video: VideoTool },
            { image: uploadImage, video: uploadVideo },
            loadWidgetLink
          ),
          i18n: getLanguage() === 'ru' ? editorI18n : undefined,

          onChange: async () => {
            try {
              const savedData = await editorInstance?.current?.save();
              if (!savedData) return;

              const { blocks } = savedData;
              /*              if (blocks.length > MAX_BLOCK_COUNT) {
                Toast.error(
                  t('news:max_editor_blocks', { count: MAX_BLOCK_COUNT })
                );
                return;
              }*/
              blocksChange(blocks);
            } catch (error) {}
          },
          data: { blocks: initBlocks },
        });
      } catch (e: unknown) {
        Toast.error(t('common:errors:something_went_wrong'));
      }
    };

    const handleTabPress = (event: KeyboardEvent) => {
      if (event.key === 'Tab' && editorContainer.current?.matches(':hover')) {
        event.preventDefault();

        const plusButton =
          editorContainer.current?.querySelector('.ce-toolbar__plus');
        const popoverOpened = editorContainer.current?.querySelector(
          '.ce-popover.ce-popover--opened'
        );

        if (plusButton && !popoverOpened) {
          (plusButton as HTMLElement).click();
        }
      }
    };

    document.addEventListener('keydown', handleTabPress);
    initializeEditorInstance();

    const setBlockLabels = () => {
      const editorTextBlock = t('editor:paragraph');
      const editorListBlock = t('editor:list');
      const editorTitleBlock = t('editor:title');
      const editorImageDescBlock = t('editor:image_desc');
      const editorVideoDescBlock = t('editor:video_desc');

      document.documentElement.style.setProperty(
        '--paragraph',
        `"${editorTextBlock}"`
      );
      document.documentElement.style.setProperty(
        '--list',
        `"${editorListBlock}"`
      );
      document.documentElement.style.setProperty(
        '--title',
        `"${editorTitleBlock}"`
      );
      document.documentElement.style.setProperty(
        '--caption-image',
        `"${editorImageDescBlock}"`
      );
      document.documentElement.style.setProperty(
        '--caption-video',
        `"${editorVideoDescBlock}"`
      );
    };

    setBlockLabels();

    return () => {
      document.removeEventListener('keydown', handleTabPress);

      if (!editorInstance.current) return;

      const destroyEditorInstance = async () => {
        try {
          await editorInstance?.current?.isReady;
          editorInstance?.current?.destroy();
          editorInstance.current = null;
        } catch (e) {}
      };

      destroyEditorInstance();
    };
  }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <div className={css.Root}>
      <div ref={editorContainer} id={css.editor} />
    </div>
  );
};

export default Editor;
